.highlight-section-btn {
  width: 200px;
  height: 230px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 80ms;
  margin: 0 0.5rem 2rem 0.5rem;
  background-color: #EEEEEE;
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.highlight-section-btn:hover {
  transform: scale(1.05);
}

.highlight-section-btn__circle {
  border-radius: .75rem;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight-section-btn__square {
  border-radius: .75rem;
  height:  9rem;
  border: 3px solid #24428e;
  width: 9rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.125rem 0.125rem 0.25rem 0.125rem rgba(211, 211, 211, 0.651);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .2rem;
  padding: 1rem .8rem;
}

.highlight-section-btn__circle {
  margin: 0;
}

.button-image {
  width: 85%;
}

.highlight-section-btn__label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 8px;
  height: 3rem;
  border-radius: 1rem;
  background: rgba(129, 164, 255, 0.50);
  text-align: center;
  line-height: normal;
  color: #203F8F;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
}

.events-list-item__info__buttons button {
  min-width: fit-content;
}

@media only screen and (max-width: 970px) {
  .highlight-section-btn__circle {
    height: 5rem;
    width: 5rem;
  }
  .button-image {
    width: 60%;
  }
  .highlight-section-btn__square {
    height: 7rem;
    width: 7rem;
    padding: .7rem .5rem;
  }
  .highlight-section-btn__square p {
    font-size: small;
  }
  .highlight-section-btn__label {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 785px) {
  .highlight-section-btn {
    margin: 0 0.25rem 2rem 0.25rem;
  }
  .highlight-section-btn__circle {
    height: 5.5rem;
    width: 5.5rem;
  }
  .highlight-section-btn__square {
    height: 5.5rem;
    width: 5.5rem;
    padding: .4rem .2rem;
  }
}
