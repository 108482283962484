.cardContainerEvent {
  width: 100%;
  height: 80vh;
  overflow: auto;
}
.cardEvent {
  display: flex;
}
.btnLinkEvent {
  position: absolute;
  bottom: 4.5rem;
  right: 15rem;
}
.btnSeeMore {
  position: absolute;
  bottom: 5%;
  right: 6rem;
}
.btnSeeMoreDetails {
  margin: 2% 2%;
}
.titleCardEvent {
  font-size: 1.9rem;
  line-height: 2rem;
  margin: 0.8rem 0rem 1rem;
  width: 100%;
  color: #585859;
}
.dateNumberCardEvent {
  font-size: 3.5rem;
  margin-top: -1rem;
  color: #585859;
}
.dateNumberCardCommunication {
  font-size: 2.5rem;
  margin-top: -1rem;
  color: #585859;
}
.categoryCardEvent {
  padding: 0.1rem;
  margin: 0.5rem 0rem -0.5rem;
  text-transform: uppercase;
  color: #24428e;
}

@media only screen and (max-width: 600px) {
  .cardContainerEvent {
    width: 100%;
    height: 80vh;
    overflow: inherit;
  }
  .cardEvent {
    margin-top: 8%;
  }
  .btnSeeMore {
    right: 1rem;
    position: relative;
    margin: 8% 10%;
  }
  .btnLinkEvent {
    position: static;
    margin: 5% 20%;
  }
  .btnSeeMoreDetails {
    padding: 1rem 5rem;
  }
  .titleCardEvent {
    margin: 0.4rem 0rem 0.4rem;
    line-height: 1.8rem;
    font-size: 1.2rem;
  }
  .categoryCardEvent {
    padding: 0.1rem;
    margin: 0.1rem 0rem -0.5rem;
    text-transform: uppercase;
    color: #24428e;
  }
  .dateNumberCardEvent {
    font-size: 3rem;
    margin-top: -0.4rem;
    color: #585859;
  }
}
