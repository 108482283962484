.events-list-item {
  font-family: Inter;
  display: flex;
  max-width: 35rem;
  height: 130px;
  background: #EEE;
  box-shadow: 4px 4px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}
.events-list-item__date {
  background: #E2E8F0;
  color: #000;
  min-width: 6rem;
  width: 10.625rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.events-list-item__date__month {
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.events-list-item__date__day {
  font-size: 4rem;
  font-style: normal;
  line-height: 82.523%;
}
.events-list-item__info {
  font-family: Inter;
  flex: 1;
  padding: 1.5rem 1.5rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.events-list-item__info__title {
  color: #434343;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.48156rem;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.events-list-item__info__buttons {
  display: flex;
  gap: 0.5rem;
}
