.supplier-card {
    display: flex;
    flex-direction: column;
    padding: 0.5rem !important;
    border-radius: 0.5rem !important;
    overflow: hidden;
    transition: all ease 250ms
}
.supplier-card:hover {
    transform: scale(1.04) !important
}