.ant-select-selector {
  font-family: 'Nunito', sans-serif !important;
  width: 100%;
  align-items: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  padding-left: 1rem !important;
  padding-right: 1rem;
  min-height: 2.5rem !important;
  border-radius: 0.25rem !important;
  border: 1px solid;
  border-color: #e2e8f0 !important;
  background-color: #fff;
}
.ant-select-selection-placeholder {
  font-family: 'Nunito', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  color: #aabbcc !important;
}
.ant-select-selection-search {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
}
.ant-select-multiple .ant-select-selection-placeholder {
  margin-left: 0.25rem;
}
.ant-select-multiple .ant-select-selector {
  padding-left: 0.5rem !important;
}
