.custom-nav-menu-button {
}

.custom-nav-item {
    padding: 0 8px;
}

.custom-nav-item:hover {
    border-radius: 50px;
    background: rgba(129, 164, 255, 0.50);
}