:root {
  --color-blue-700: #203f8f;
  --soft-text: #3e434e;
}
* {
  box-sizing: border-box;
}
.emergency-phone {
  background: transparent;
  display: inline-block;
  text-align: center;
  margin: auto;
  width: 100%;
  justify-content: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #fff;
  padding: 0.25rem;
}
.emergency-phone span {
  white-space: nowrap;
}
.pointer:hover {
  cursor: pointer;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.hide-password {
  font-family: 'password';
  width: 100px;
  height: 16px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #e2e8f0 !important;
}

.divider-bar {
  margin-top: 1rem;
  width: 100%;
  height: 5px;
  border-radius: 2.5rem;
  background: rgb(32, 63, 143);
  background: linear-gradient(90deg, rgba(32, 63, 143, 1) 0%, rgba(129, 164, 255, 1) 100%);
}
