.language-popover {
  transform: translate(6px, -10px);
}

.language-selector {
  display: flex;
  gap: 10px;
}

.language-opener-button,
.language-button {
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  align-items: center;
}

.language-button {
  padding: 0.4rem;
  color: var(--soft-text);
  width: 14rem;
}

.language-label {
  font-size: 0.875rem;
  line-height: 0.75rem;
}

.language-button:hover .language-label {
  text-decoration: underline;
}

.flag-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.language-button.selected {
  color: black;
  font-weight: 500;
}
