.cardTraining{
    width:100%;
     height:70vh;
      overflow:auto
}
.trainingTitleCard{
    padding:0.8rem 1.2rem 0.5rem;
    color:#24428e;
    line-height : 2.2rem;
    font-size:2rem;
    font-weight:bold
}
.btnEditAcces{
    position:absolute;
    width: 200%;
    top: 0.2rem;
    left: 0.2rem
}
.btnSeeVideo{
    position: absolute;
    bottom:15%; 
    right: 1rem
}
.trainingDateCard{
    padding:0.3rem 1.3rem;
    font-size: 1.1rem;
     color:gray;
     width: 75%;
     line-height : 1.5rem
}
.dividerGeaInHome{
    border-color:blue;
     width:100%;
      margin:1.3% 0% 1.6%
}
@media only screen and (max-width: 600px) {
    .cardTraining{
        margin-left: -5%;
        width:130%;
    }
    .btnSeeVideo{
        position: relative;
        bottom:5%; 
        width:50%;
        padding:2%;
        margin-left:10%;
    }
    .btnSeeVideoDetails{
        padding: 1rem 5rem
    }
    .trainingTitleCard{
    padding:0.8rem 1.2rem 0.5rem;
    color:'#24428e';
    line-height : 2.2rem;
    font-size:1.7rem;
    font-weight:bold;

    }
    .btnEditAcces{
        display:none
    }
   
    .trainingDateCard{
        font-size: 0.9rem;
    }
    .dividerGeaInHome{
        border-color:blue;
        width:75%;
        margin:1.3% 5% 1.6%
    }
  }