.titleEventHome{
    margin:0.5rem 0rem 0rem -0.5rem;
    font-size:1.4rem;
    width:300%
}
.boxLinksEventHome{
    display:flex
}
.btnSeeMoreEventHome{
    background:#103059 !important;
    margin:0rem 1.5rem 0rem -0.8rem !important;
    height:1.4rem !important
}
.btnLinkEventHome{
    margin:0rem 0.5rem 0rem -0.8rem !important;
    height:1.4rem !important
}
@media only screen and (max-width: 600px) {
    .titleEventHome{
        margin:0.5rem 0rem 0rem 0.8rem;
        font-size:1.2rem;
         width:300%
    }
    .boxLinksEventHome{
        display:block
    }
    .btnSeeMoreEventHome{
        margin:0.1rem 0rem 0rem 0.8rem !important;
    }
    .btnLinkEventHome{
        margin:0.3rem 0rem 0rem 0.8rem !important;
    }
  }