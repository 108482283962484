.news-card {
  border-radius: 0.625rem;
  overflow: hidden;
  min-width: 22.51406rem;
  height: 18.5rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
  transition: all ease-in-out 80ms;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.news-card:hover {
  transform: scale(1.05);
}
.news-card__img {
  width: 100%;
  height: 12rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
}
.news-card__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 0.6rem 1.2rem 1.2rem 1.2rem;
  height: 3rem;
}
.news-card__info__date {
  line-height: normal;
  font-weight: 600;
  font-size: 1.125rem;
}
.news-card__info p {
  margin-top: 0.5rem;
  width: fit-content;
  font-weight: 500;
  font-size: 1rem;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  color: #7E7E7E
}
