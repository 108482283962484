footer.footer-home {
  background: #144DBE;
  padding: 1rem 0;
  color: white;
}
.section-title {
  font-size: 1.5rem;
  line-height: 80%;
}
.footer-content {
  display: flex;
  align-items: stretch;
  gap: 2rem 5rem;
  padding: 0 3rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.social-media-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  flex-shrink: 1;
  padding-bottom: 0.25rem;
}
.social-media-section .icons {
  display: flex;
  gap: 0.5rem;
}
.social-media-section .icons svg {
  cursor: pointer;
  font-size: 1.5rem;
}
.contact-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.contact-section .content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.contact-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.contact-line-br {
  display: flex;
  gap: 0.5rem;
}
.contact-line svg {
  margin-left: 0.2rem;
}
