html {
  overflow-y: scroll;
}
html,
body,
div#root {
  height: 100%;
  font-family: 'Nunito', sans-serif;
}
.page-end-spacing {
  height: 6rem;
}

.date-wrapper .DateRangePicker .DateInput_input__small {
  letter-spacing: -0.8px;
}
.DateRangePicker {
  width: 100%;
}
.DateRangePickerInput {
  display: flex !important;
  align-items: center;
  height: 2.5rem;
  border-radius: 0.25rem !important;
  border: 1px solid !important;
  border-color: inherit !important;
  background-color: #fff !important;
  overflow: hidden;
  transition: all 0.2s !important;
}
.DateRangePicker:hover .DateRangePickerInput {
  border-color: #cbd5e0 !important;
}
.DateRangePickerInput .DateInput {
  flex: 1;
  height: 100%;
}
.DateRangePickerInput .DateInput input {
  text-align: center;
  height: 100%;
}
