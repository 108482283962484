.informationCardNews{
    width:65%;
    padding-left:1%
}
.backgroundNews{
    background-color: transparent;
    border-bottom: 55px;
}
.titleCardNews{ 
   font-size:20pt;
   padding:0.5rem 1.2rem 0rem;
   color:#24428e;
   line-height : 1.5rem
}
.imageCardNews{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
 }
.dateCardNews{
    padding:0.2rem 1.2rem 0rem; 
    color:gray;
    line-height : 1.5rem
}
@media only screen and (max-width: 600px) {
    .informationCardNews{
        width:100%;
        padding-left:1%
    }
    .backgroundNews{
        background-color: white;
        margin-bottom: 5%;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
    }
    .titleCardNews{
        font-size:18pt;
        padding:0.8rem 1.2rem 0.2rem;
        color:#24428e;
        line-height : 1.8rem
     }
     .imageCardNews{
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
     }
    .dateCardNews{
        padding:0.2rem 1.2rem 1rem; 
        color:gray;
        line-height : 1.5rem
    }
  }