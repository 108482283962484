.cardsNews:hover {
  transform: scale(1.03);
}
.news-bar {
  display: flex;
  overflow-x: auto;
  padding: 1.5rem 0.5rem;
  margin-left: 3rem;
  gap: 2rem;
  position: relative;
}
.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
}
.gradient.left {
  left: 0;
  width: 3rem;
  background: linear-gradient(
    90deg,
    rgba(244, 244, 246, 1) 0%,
    rgba(244, 244, 246, 0) 100%
  );
}
.gradient.right {
  right: 0;
  width: 3rem;
  background: linear-gradient(
    90deg,
    rgba(244, 244, 246, 0) 0%,
    rgba(244, 244, 246, 1) 100%
  );
}
/* width */
::-webkit-scrollbar {
  width: 0.75rem!important;
  height: 0.75rem!important;
}

::-webkit-scrollbar:hover {
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0.35rem;
  background: rgba(255, 255, 255, 0.582);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a5b2c3;
  border-radius: 0.35rem;
  border: 5px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #24428e;
}

/* Track */
.custom-inner-scrollbar::-webkit-scrollbar-track {
  border-radius: 0.35rem;
  background: #81A4FF;
}

/* Handle */
.custom-inner-scrollbar::-webkit-scrollbar-thumb {
  background: #203F8F;
}

/* Handle on hover */
.custom-inner-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #113285;
}

.events-list::-webkit-scrollbar-track {
  right: -10px;
}
.events-list {
  background: #81A4FF;
  display: grid;
  padding: 1rem 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.25rem 1.75rem;
  overflow-x: auto;
}
.events-list2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  padding: 0 rem 0.75rem;
  max-width: 32rem;
  min-width: 17rem;
  width: fit-content;
}
@media only screen and (max-width: 600px) {
  .seccionesDestacadasHome {
    display: none;
  }
}

.seccionesDestacadasHome {
  background: white;
  padding-top: 20px;
  padding-bottom: 5px
}
