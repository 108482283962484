.SingleDatePickerInput {
    display: flex;
    height: 2.5rem;
    border-radius: 0.25rem;
    align-items: center;
}

.DateInput {
    margin-left: 10px;
}

.DateInput_input {
    padding: 5px; 
    border: unset;
    font-size: 1rem;
}