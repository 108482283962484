.custom-splide-pagination > li > button.is-active {
    transform: none;
    background: #203F8F;
  }
  
  .custom-splide-pagination > li > button {
    background: #81A4FF;
    border-radius: 1rem;
    width: 10rem;
    height: 6px;
  }
  
  .custom-splide-pagination > li:first-child > button {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  
  .custom-splide-pagination > li:not(:first-child):not(:last-child) > button {
    border-radius: 0px;
  }
  
  .custom-splide-pagination > li:last-child > button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  
  .custom-splide-pagination {
    bottom: -1rem;
  }