.menuListNavBar{
    background-color: #144DBE !important;
    /* background-color: #1F408F !important; */
}
.menuItemNavBar:hover{
    /* background-color:#193575 !important */
      background-color: #0C2D57 !important;
}

.menuItemNavBar:focus{
    /* background-color:#193575 !important */
      background-color: #0C2D57 !important;
}