.pagination-container {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0.5rem;
  right: 0.5rem;
}
.pagination__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin: 0 0.25rem;
  transition: all ease-in-out 80ms;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.307);
}
.pagination__input.focus,
.pagination__btn.active {
  transform: scale(1.04);
  filter: brightness(95%);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.307);
}
.pagination__btn.inactive {
  opacity: 0.8;
}
.pagination__total {
  display: flex;
  align-items: center;
}
.pagination__more {
  display: flex;
  justify-content: center;
  width: 0rem;
  overflow: hidden;
  font-weight: 600;
  font-size: 1rem;
  transition: all ease-in-out 100ms;
}
.pagination__more.show {
  width: 1rem;
}

.pagination__input {
  margin-left: 1rem;
  border-radius: 0.25rem;
  font-weight: 600;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.2;
  outline: none;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  padding-left: 1rem;
  background-color: #edf2f7;
  transition: all ease-in-out 80ms;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.307);
  cursor: text;
}
.pagination__input input {
  margin: 0 0.25rem;
  background: transparent;
  width: 2.5rem;
}
.pagination__go {
  padding: 0 0.5rem !important;
}
.pagination__input input:focus-visible {
  outline: unset;
}

.pagination__input input:focus-visible .pagination__input {
  outline: -webkit-focus-ring-color auto 1px;
}
